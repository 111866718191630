body {
    background-color: #26282b;
}
h4 {
    color: #fff;
}
img {
    pointer-events: none;
}
#PlexText {
    color: #e5a00d;
}
#DiscordText {
    color: #5865F2;
}
#username {
    text-decoration: underline;
}
h3 {
    color: red
}